import React from "react";
import Hero from "./Hero.js";

function HomePage() {
  return (
    <div id="home-page">
      <Hero />
    </div>
  );
}

export default HomePage;
